import React from "react";
import { Avatar, Card, Col, Image, Row } from "antd";
import Meta from "antd/lib/card/Meta";

PatientDetailInformation.propTypes = {};

function PatientDetailInformation({ patient, loading }) {
  return (
    <Card title="Thông tin bệnh nhân" loading={loading} bordered={true}>
      <Row className="patient-information-content">
        <Col className="patient-information-content-avatar">
          <Meta
            avatar={
              <Avatar
                src={patient?.avatar}
                style={{ width: 100, height: 100 }}
              />
            }
          />
        </Col>
        <Col className="patient-information-content-info">
          <Row>
            <Col span={12}>
              {patient?.name ? (
                <div className="info-group">
                  <div className="info-group-title">Họ và tên:</div>
                  <div className="info-group-content">{patient.name}</div>
                </div>
              ) : null}
            </Col>
            <Col span={12}>
              {patient?.gender ? (
                <div className="info-group">
                  <div className="info-group-title">Giới tính:</div>
                  <div className="info-group-content">
                    {patient.gender === "male" ? "Nam" : "Nữ"}
                  </div>
                </div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {patient?.phone ? (
                <div className="info-group">
                  <div className="info-group-title">Số điện thoại:</div>
                  <div className="info-group-content">{patient.phone}</div>
                </div>
              ) : null}
            </Col>

            <Col span={12}>
              {patient?.dob ? (
                <div className="info-group">
                  <div className="info-group-title">Ngày sinh:</div>
                  <div className="info-group-content">{patient.dob}</div>
                </div>
              ) : null}
            </Col>
          </Row>
          {patient?.address ? (
            <div className="info-group">
              <div className="info-group-title">Địa chỉ:</div>
              <div className="info-group-content-address">
                {patient.address}
              </div>
            </div>
          ) : null}
        </Col>
      </Row>
    </Card>
  );
}

export default PatientDetailInformation;
