import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";

function IconConfirm({ onClick }) {
  return (
    <CheckCircleOutlined
      title="Xác nhận thanh toán"
      style={{ color: "blue" }}
      onClick={onClick}
    />
  );
}

export default IconConfirm;
